/* General styles */
.services-section {
  background-color: #0c1e34;
  color: white;
  padding: 50px 20px;
}

.services-container {
  margin-left: 20px;
}

.services-heading {
  font-size: 3rem;
  margin-bottom: 20px;
  text-align: center;
  font-family: 'overpassextrabold', sans-serif;
  color: white;
}

.services-tagline {
  font-size: 1.2rem;
  margin-bottom: 40px;
  color: #b0b8c1;
  font-family: 'overpasssemibold', sans-serif;
  text-align: center;
}

.services-card-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Default for larger screens */
  gap: 20px;
}

.services-card {
  background-color: #112240;
  padding: 20px;
  border-radius: 10px;
  transition: transform 0.3s;
}

.services-card:hover {
  transform: translateY(-10px);
}

.services-icon {
  font-size: 2rem;
  margin-bottom: 20px;
}

.services-card-title {
  font-size: 1.5rem;
  margin-bottom: 15px;
  font-family: 'overpasssemibold', sans-serif;
  color: #fff;
}

.services-card-description {
  font-size: 1rem;
  color: #b0b8c1;
  margin-bottom: 20px;
  font-family: 'Overpass_Regular', sans-serif;
}

.services-card-link {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  font-family: 'overpassextralight', sans-serif;
}

.services-card-link:hover {
  text-decoration: underline;
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  .services-card-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .services-card-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .services-heading {
    font-size: 2.5rem;
  }

  .services-tagline {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .services-card-grid {
    grid-template-columns: 1fr;
  }

  .services-heading {
    font-size: 2rem;
  }

  .services-tagline {
    font-size: 0.9rem;
  }

  .services-icon {
    font-size: 1.5rem;
  }

  .services-card-title {
    font-size: 1.2rem;
  }

  .services-card-description {
    font-size: 0.9rem;
  }

  .services-card-link {
    font-size: 0.9rem;
  }

  .services-section {
    padding: 30px 15px;
  }

  .services-container {
    margin-left: 0;
  }
}
