* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.home-container {
  font-family: Arial, sans-serif;
}

.logo {
  font-size: 28px;
  color: #fff;
  font-weight: bold;
}

.perks-text {
  text-align: center;
  font-family: 'overpasssemibold', sans-serif;
}

.nav-links {
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 30px;
}

.nav-links li {
  position: relative;
}

.nav-links a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  padding: 10px;
  transition: border-bottom 0.3s;
}

.nav-links a:hover {
  border-bottom: 2px solid #fff;
}

/* Hero section */
.hero-section {
  background-image: url('https://creativeblenddesign.com/wp-content/uploads/2024/02/cbd-AI-2024-blog.jpg');
  background-size: cover;
  background-position: center;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-text-dev-data {
  text-align: center;
  padding: 20px;
  margin-top: 50px;
}

.hero-text {
  color: #fff;
}

.hero-text h1 {
  font-size: 40px;
  margin-bottom: 20px;
}

.hero-text p {
  font-size: 18px;
  margin-bottom: 30px;
}

.cta-button1 {
  padding: 12px 24px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cta-button1:hover {
  background-color: #0056b3;
}

/* About container */
.about-container {
  padding: 50px 100px;
  text-align: center;

}

.about-container h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.about-container p {
  font-size: 16px;
  margin-bottom: 30px;
}

/* Features grid */
.features-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.feature-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
}

.icon {
  width: 50px;
  height: auto;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  /* Hero text adjustments for tablet */
  .hero-text h1 {
    font-size: 32px;
  }

  .hero-text p {
    font-size: 16px;
  }

  .cta-button1 {
    font-size: 14px;
    padding: 10px 20px;
  }

  .about-container {
    padding: 30px 20px;
  }

  .about-container h1 {
    font-size: 28px;
  }

  .about-container p {
    font-size: 14px;
  }

  .features-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
}

@media (max-width: 480px) {
  /* Hero section adjustments for mobile */
  .hero-section {
    height: 300px;
    padding: 10px;
  }

  .hero-text h1 {
    font-size: 24px;
  }

  .hero-text p {
    font-size: 14px;
  }

  .cta-button1 {
    font-size: 12px;
    padding: 8px 16px;
  }

  /* About container adjustments */
  .about-container {
    padding: 20px 10px;
  }

  .about-container h1 {
    font-size: 24px;
  }

  .about-container p {
    font-size: 12px;
  }

  /* Features grid for mobile */
  .features-grid {
    grid-template-columns: 1fr; /* Stack items vertically */
  }

  .feature-card {
    padding: 15px;
  }
}

.Create-head {
  font-family: 'overpassheavy', sans-serif;
}

.Create-head-disc {
  font-family: 'overpasssemibold', sans-serif;
}

.Join-text {
  font-family: 'overpasssemibold', sans-serif;
  text-align: center;
  font-weight: bold;
}

.Join-text-dis {
  font-family: 'Overpass_Regular', sans-serif;
  margin-left: 40px;
  margin-right: 40px;
}

.feature-card-text {
  font-family: 'overpasssemibold', sans-serif;
}
