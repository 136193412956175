.contact-form {
  max-width: 900px;
  margin: 0 auto;
  padding: 40px;
  background-color: white;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

h2 {
  font-size: 36px;
  margin-bottom: 10px;
}

p {
  font-size: 16px;
  margin-bottom: 30px;
}
.phone-input::placeholder {
  color: #999; /* Change this color as needed */
}
.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 30px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  font-size: 16px;
  margin-bottom: 10px;
}

input, textarea, select {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  outline: none;
}

textarea {
  height: 120px;
  resize: none;
}

input.error, textarea.error, select.error {
  border-color: red;
}

.error-text {
  color: red;
  font-size: 14px;
}

.submit-btn {
  background-color: #007bff;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  font-family: 'Overpass_Regular', sans-serif;

}

.submit-btn:hover {
  background-color: #0056b3;
}

.char-count {
  text-align: right;
  font-size: 14px;
  margin-top: 5px;
  color: #666;
}

/* Phone input styling */
.react-tel-input .form-control {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-top: 5px;
}

/* reCAPTCHA styling */
.g-recaptcha {
  margin-top: 20px;

}
.contact-details-titleA{
  font-family: 'Overpass_Regular', sans-serif;
  margin-top: 6px;

}
.contact-details-title{
  font-family: 'Overpass_Regular', sans-serif;
}
.contact-title{
  font-family: 'overpassheavy', sans-serif;
  color: #000;
}
.submitted-popup{
  font-family: 'overpassheavy', sans-serif;
  color: #4caf50;
}
/* .popup {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4caf50;
  color: white;
  padding: 1rem 2rem;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  text-align: center;
} */
 