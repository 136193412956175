* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: rgba(40, 44, 52, 0.8); /* Transparent background for blur effect */
  backdrop-filter: blur(3px); /* Apply blur effect */
  color: white;
  position: fixed; /* Fix the header to the top */
  width: 100%; /* Make sure the header stretches across the full width */
  top: 0;
  left: 0;
  z-index: 1000; /* Ensure the header stays above other elements */
  transition: background-color 0.3s;
}

.logo {
  font-size: 1.8rem;
  font-weight: bold;
  cursor: pointer;
  margin-left: 50px;
}

.menu-icon {
  font-size: 2.5rem;
  cursor: pointer;
  display: none; /* Hidden on larger screens */
}

.nav {
  display: flex;
  align-items: center;
}

.menu {
  display: flex;
  list-style: none;
  align-items: center;
  gap: 2rem;
  margin-right: 100px; /* Add some margin to the right */
}

.menu li {
  position: relative;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  transition: color 0.3s, transform 0.3s;
  color: #fff; /* Set default text color for all menu items */
}

.menu li.services-menu {
  color: #fff; /* Set text color for Our Services to white */
}

.menu li:hover {
  color: #61dafb;
  transform: scale(1.05);
}

.submenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: rgba(40, 44, 52, 0.8); /* Transparent background for blur effect */
  backdrop-filter: blur(3px); /* Apply blur effect */
  border-radius: 5px;
  padding: 0.5rem;
  animation: fadeIn 0.1s ease-in-out;
  list-style-type: none; /* Remove bullet points */
}

.submenu.open {
  display: block;
}

.submenu li {
  padding: 0.5rem 1rem;
  white-space: nowrap;
  transition: background-color 0.3s;
}

.submenu li:hover {
  background-color: #555;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Mobile Styles */
@media (max-width: 768px) {
  .menu-icon {
    display: block; /* Show menu icon on mobile */
  }

  .nav {
    display: none; /* Hide nav by default on mobile */
    position: absolute;
    top: 60px; /* Adjust based on header height */
    left: 0;
    width: 100%;
    background-color: rgba(40, 44, 52, 0.9); /* Keep blur visible on mobile */
    z-index: 1;
    animation: slideDown 0.5s ease-in-out;
  }

  .nav.open {
    display: block; /* Show nav when open */
  }

  .menu {
    flex-direction: column; /* Stack items vertically on mobile */
    width: 100%;
    text-align: center;
    gap: 0;
  }

  .menu li {
    padding: 1rem;
  }

  .submenu {
    position: static;
    width: 100%;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.menu li a {
  text-decoration: none; /* Remove underline */
  color: #fff; /* Set text color to white */
}

.menu li a:hover {
  color: #61dafb; /* Optional: Change color on hover */
}
