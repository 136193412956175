/* Main container styling */
.CarrersMedia-section {
  background-color: #0b2438; /* Dark background */
  color: white;
  padding: 60px 20px;
  text-align: center;
  font-family: Arial, sans-serif;
}

/* Header section (Title + Paragraph) */
.CarrersMedia-header h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.CarrersMedia-header p {
  font-size: 1.2rem;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 50px;
}

/* Grid layout for CarrersMedia cards */
.CarrersMedia-grid {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

/* Individual card styling */
.CarrersMedia-card {
  background-color: white;
  color: #333;
  border-radius: 10px;
  padding: 20px;
  width: calc(25% - 40px); /* Adjust width dynamically */
  min-width: 200px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Card hover effect */
.CarrersMedia-card:hover {
  transform: translateY(-5px);
}

/* Profile image */
.CarrersMedia-card img {
  width: 100%; /* Make image responsive */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px;
  margin-bottom: 10px;
}

/* Card title and description */
.CarrersMedia-card h3 {
  font-size: 1.4rem;
  margin-bottom: 10px;
}

.CarrersMedia-card h4 {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 15px;
}

.CarrersMedia-card p {
  font-size: 1rem;
  color: #777;
  margin-bottom: 20px;
}

/* Read more link */
.CarrersMedia-card a {
  font-size: 1rem;
  color: #007BFF;
  text-decoration: none;
}

.CarrersMedia-card a:hover {
  text-decoration: underline;
}

/* Responsive design */
@media (max-width: 768px) {
  .CarrersMedia-grid {
      flex-direction: column;
      align-items: center;
  }

  .CarrersMedia-card {
      width: 90%; /* Make cards full width on small screens */
      margin-bottom: 30px;
  }
}

.fingerss-media{
  font-family: 'overpassheavy',sans-serif;
  color: #fff;
}
.fingerss-detail{
  font-family: 'Overpass_Regular',sans-serif;
  color: #fff;
}
.media-name{
  font-family: 'overpassheavy',sans-serif;
  /* color: #fff; */
}
.media-title{
  font-family: 'overpasssemibold',sans-serif;
  /* color: #fff; */
}
.media-dis{
  font-family: 'Overpass_Regular',sans-serif;
  /* color: #fff; */
}