/* Main container styling */
.mission-section {
  background-color: #0b2438; /* Dark background */
  color: white;
  padding: 60px 20px;
  text-align: center;
  font-family: Arial, sans-serif;
}

/* Header section (Title + Paragraph) */
.mission-header h1 {
  font-size: 2.5rem; /* Slightly smaller font size for better mobile view */
  margin-bottom: 20px;
}

.mission-header p {
  font-size: 1rem; /* Adjusted font size for mobile */
  line-height: 1.5; /* Adjusted line height for better readability */
  max-width: 90%; /* Full width on mobile */
  margin: 0 auto;
  margin-bottom: 30px; /* Adjusted bottom margin */
}

/* Cards container */
.mission-cards {
  display: flex;
  justify-content: space-between; /* Space out cards */
  flex-wrap: wrap; /* Allow wrapping of cards */
  gap: 20px; /* Gap between cards */
}

/* Individual card styling */
.mission-card {
  background-color: #1a2b41; /* Slightly lighter background for cards */
  border-radius: 10px;
  padding: 30px;
  width: calc(25% - 20px); /* Adjust width for larger screens */
  min-width: 200px; /* Minimum width for cards */
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mission-card:hover {
  transform: translateY(-5px);
}

/* Placeholder for icons */
.icon-placeholder {
  width: 50px;
  height: 50px;
  background-color: #ffffff30;
  margin-bottom: 20px;
  border-radius: 50%;
}

/* Card title */
.mission-card h3 {
  font-size: 1.25rem; /* Slightly smaller font size for titles */
  margin-bottom: 10px;
}

/* Card description */
.mission-card p {
  font-size: 0.9rem; /* Adjusted font size for better mobile view */
  color: #ccc;
  text-align: center;
}

/* Responsive design */
@media (max-width: 768px) {
  .mission-header h1 {
    font-size: 2rem; /* Smaller header size for mobile */
  }

  .mission-header p {
    font-size: 0.9rem; /* Smaller paragraph size for mobile */
  }

  .mission-cards {
    flex-direction: column; /* Stack cards vertically */
    align-items: center; /* Center cards */
  }

  .mission-card {
    width: 90%; /* Full width on mobile */
    margin-bottom: 30px; /* Space between stacked cards */
  }
}

.our-miss{
  font-family: 'overpassheavy',sans-serif;
  /* margin-left: 40px; */
  color: #fff;
}

.our-dis{
  font-family: 'Overpass_Regular',sans-serif;
  /* margin-left: 40px; */
  color: #fff;
}

.card-title {
  color: #fff
}
.card-dis{
  font-family: 'Overpass_Regular',sans-serif;
  /* margin-left: 40px; */
  color: #fff;
}