/* Styling for the Our Work section */
.our-work-section {
    padding: 50px 20px;
    background-color: #f8f9fa;
  }
  
  .section-title {
    font-size: 2.5rem;
    margin-bottom: 10px;
    text-align: center;
    color: #000;
    font-family: 'overpassextrabold', sans-serif;

  }
  
  .section-subtitle {
    font-size: 1.2rem;
    color: #666;
    text-align: center;
    margin-bottom: 40px;
    font-family: 'Overpass_Regular', sans-serif;
    margin-left: 20px;

  }
  
  /* Grid for the case studies */
  .case-studies-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-bottom: 40px;
  }
  
  /* Make grid responsive for smaller screens */
  @media (max-width: 1024px) {
    .case-studies-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .case-studies-grid {
      grid-template-columns: 1fr;
    }
  }
  
  /* Individual card styling */
  .case-study-card {
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    margin-left: 20px;
  }
  
  .case-study-card:hover {
    transform: translateY(-10px);
  }
  
  .case-study-image {
    width: 100%;
    height: 180px;
    object-fit: cover;
  }
  
  .case-study-content {
    padding: 20px;
  }
  
  .case-study-title {
    font-size: 1.25rem;
    margin-bottom: 10px;
    font-family: 'overpasssemibold', sans-serif;

  }
  
  .case-study-description {
    font-size: 1rem;
    color: #666;
    margin-bottom: 15px;
    font-family: 'Overpass_Regular', sans-serif;

  }
  
  .case-study-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
    font-family: 'overpasssemibold', sans-serif;

  }
  
  .tag {
    background-color: #e9ecef;
    color: #495057;
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 0.875rem;
    font-family: 'overpasssemibold', sans-serif;

  }
  
  .case-study-link {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
    font-family: 'Overpass_Regular', sans-serif;

  }
  
  .case-study-link:hover {
    text-decoration: underline;
  }
  
  /* Additional section for "40+ case studies" */
  .more-case-studies {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-left: 20px;
  }
  
  .more-case-studies h2 {
    font-size: 2rem;
    margin-bottom: 5px;
  }
  
  .more-case-studies p {
    font-size: 1rem;
    color: #666;
  }
  .more-40 {
    font-size: 1rem;
    color: #666;
  }
  
  @media (max-width: 768px) {
    .section-title {
      font-size: 2rem;
    color: #000;

    }
  
    .more-case-studies h2 {
      font-size: 1.75rem;
    }
  }
  