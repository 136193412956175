.container {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
    margin-top: 20px;
  }
  
  .title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    font-family: 'overpassheavy',sans-serif;
  }
  
  .description {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 30px;
    font-family: 'Overpass_Regular',sans-serif;

  }
  
  .info-box {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
    margin: 20px 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    font-family: 'Overpass_Regular',sans-serif;
    
  }
  
  .info-box p {
    font-size: 1rem;
    color: #333;
  }
  