/* Section Styling */
.expertise-section {
  background-color: #0a1e36; /* Dark blue background */
  color: #ffffff;
  padding: 60px 20px;
  text-align: center;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

/* Title and Description */
.expertise-title {
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #fff;
  font-family: 'overpassheavy',sans-serif;

}

.expertise-description {
  font-size: 18px;
  color: #b0b9c3;
  margin-bottom: 40px;
  font-family: 'Overpass_Regular',sans-serif;

}

/* Grid Layout */
.expertise-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

/* Expertise Card Styling */
.expertise-card {
  background-color: #1b2b46;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.expertise-card:hover {
  transform: translateY(-10px);
}

/* Image for Expertise Cards */
.expertise-image {
  height: 200px;
  background-size: cover;
  background-position: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

/* Card Titles */
.card-title {
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
  padding: 15px;
  text-align: center;
  font-family: 'Overpass_Regular',sans-serif;

}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .expertise-grid {
    grid-template-columns: 1fr;
  }
}

