/* Section for the How We Work */
.how-we-work-section {
  background-color: #ffffff;
  color: #000;
  padding: 60px 20px;
  text-align: center;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

/* Title and Description */
.how-we-work-title {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 15px;
  font-family: 'overpassheavy', sans-serif;
}

.how-we-work-description {
  font-size: 18px;
  color: #666;
  margin-bottom: 50px;
  font-family: 'Overpass_Regular', sans-serif;
}

/* Grid Layout */
.how-we-work-grid {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.how-we-work-card {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 30px;
  width: 45%;
  margin-bottom: 30px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: left;
  transition: transform 0.3s ease;
}

.how-we-work-card:hover {
  transform: translateY(-10px);
}

/* Icon and Card Text */
.icon {
  font-size: 36px;
  color: #007bff;
  margin-bottom: 20px;
  flex-direction: row;
}

.card-title {
  color: #000 !important;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
  font-family: 'overpasssemibold', sans-serif;
}

.card-description {
  font-size: 16px;
  color: #333;
  font-family: 'Overpass_Regular', sans-serif;
}

/* Responsive layout for smaller screens */
@media (max-width: 1024px) {
  .how-we-work-card {
    width: 100%;
  }

  .how-we-work-title {
    font-size: 36px;
  }

  .how-we-work-description {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .how-we-work-grid {
    flex-direction: column;
    align-items: center;
  }

  .how-we-work-card {
    width: 90%;
    padding: 20px;
  }

  .how-we-work-title {
    font-size: 32px;
  }

  .how-we-work-description {
    font-size: 15px;
  }

  .card-title {
    font-size: 22px;
  }

  .card-description {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .how-we-work-section {
    padding: 40px 10px;
  }

  .how-we-work-title {
    font-size: 28px;
  }

  .how-we-work-description {
    font-size: 14px;
  }

  .card-title {
    font-size: 20px;
  }

  .card-description {
    font-size: 13px;
  }

  .how-we-work-card {
    padding: 15px;
    width: 100%;
  }
}
