/* Main section styling */
.vision-section {
    /* margin-left: 168px;  */
    /* Left margin for larger screens */
    height: 300px; /* Fixed height */
    margin-bottom: 40px; /* Bottom margin */
    display: flex; /* Flexbox for centering content */
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
  }
  
  /* Content styling */
  .vision-content {
    border-width: 5px; /* Border width */
    margin-top: 100px; /* Top margin */
    text-align: center; /* Center text */
    /* padding: 20px;  */
    /* Padding around the content */
  }
  
  /* Heading styling */
  .vision-content h4 {
    font-size: 48px; /* Large font size for heading */
  }
  
  /* Paragraph styling */
  .vision-content p {
    margin-top: 50px; /* Space above the paragraph */
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .vision-section {
      margin-left: 20px; /* Reduced left margin for mobile */
      height: auto; /* Allow height to adjust based on content */
      margin-bottom: 20px; /* Adjust bottom margin */
    }
  
    .vision-content {
      margin-top: 20px; /* Reduced top margin for mobile */
      padding: 10px; /* Reduced padding for mobile */
    }
  
    .vision-content h4 {
      font-size: 32px; /* Smaller heading size for mobile */
    }
  
    .vision-content p {
      font-size: 16px; /* Smaller paragraph size for mobile */
      margin-top: 20px; /* Reduced top margin for paragraph */
    }
  }
  .our-vision{
    font-family: 'overpassheavy',sans-serif;
    /* margin-left: 40px; */
    /* color: #fff; */
  }
  .vision-dis{
    font-family: 'Overpass_Regular',sans-serif;
    margin-left: 40px;
    /* color: #fff; */
  }