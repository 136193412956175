* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.home-container {
  font-family: Arial, sans-serif;
}

/* Navbar styling */
.navbar {
  background-color: #f5f5f7;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 28px;
  color: #fff;
  font-weight: bold;
}

.nav-links {
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 30px;
}

.nav-links li {
  position: relative;
}

.nav-links a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  padding: 10px;
  transition: border-bottom 0.3s;
}

.nav-links a:hover {
  border-bottom: 2px solid #fff;
}

/* Dropdown menu styling */
.dropdown-content {
  display: block;
  position: absolute;
  top: 40px;
  left: 0;
  background-color: #fff;
  color: #000;
  list-style: none;
  padding: 15px 20px;
  border-radius: 5px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
}

.dropdown-content li {
  margin-bottom: 10px;
}

.dropdown-content li:last-child {
  margin-bottom: 0;
}

.dropdown-content a {
  color: #000;
  text-decoration: none;
}

.dropdown-content a:hover {
  color: #007bff;
}

/* Hero section */
.hero-section {
  background-image: url('https://creativeblenddesign.com/wp-content/uploads/2024/02/cbd-AI-2024-blog.jpg');
  background-size: cover;
  background-position: center;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-text {
  text-align: center;
  color: #fff;
}

.hero-text h1 {
  font-size: 40px;
  margin-bottom: 20px;
}

.hero-text p {
  font-size: 18px;
  margin-bottom: 30px;
}

.cta-button {
  padding: 12px 24px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Overpass_Regular',sans-serif;

}

.cta-button:hover {
  background-color: #0056b3;
}

.hero-title{
  font-family: 'overpassheavy',sans-serif;
  /* margin-left: 40px; */
  color: #fff;
}
.hero-dis{
  font-family: 'Overpass_Regular',sans-serif;
  /* margin-left: 40px; */
  /* color: #fff; */
}
