/* Base Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.home-container {
  font-family: Arial, sans-serif;
}

/* Header Styling */
.logo {
  font-size: 28px;
  color: #fff;
  font-weight: bold;
}

.nav-links {
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 30px;
}

.nav-links li {
  position: relative;
}

.nav-links a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  padding: 10px;
  transition: border-bottom 0.3s;
}

.nav-links a:hover {
  border-bottom: 2px solid #fff;
}

/* Hero Section */
.hero-sectionC {
  background-image: url('../../assets/images/fingerssBG.jpeg');
  background-size: cover;
  background-position: center;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-textA {
  text-align: center;
  color: #fff;
  width: 90%;
}

.hero-aboutA {
  font-family: 'overpassheavy', sans-serif;
  color: #000;
  margin-top: 80px;
}

.about-disA {
  font-family: 'Overpass_Regular', sans-serif;
  font-size: 18px;
  line-height: 1.6;
}

.cta-buttonBA {
  padding: 12px 24px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Overpass_Regular', sans-serif;
}

.cta-buttonBA:hover {
  background-color: #0056b3;
}

/* About Section */
.about-container {
  width: 90%;
  margin: 0 auto;
  padding: 50px 0;
  text-align: left;
}

.Who-are {
  font-size: 3rem;
  text-align: center;
  font-family: 'overpassheavy', sans-serif;
}

.who-dis {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-left: 100px;
  margin-right: 100px;
  font-family: 'Overpass_Regular', sans-serif;
}

/* Features Grid */
.features-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.feature-card {
  width: 80%;
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  /* Reduce the height and size of the hero section for smaller screens */
  .hero-section {
    height: 300px;
  }

  .hero-aboutA {
    font-size: 24px;
    margin-top: 40px;
  }

  .about-disA {
    font-size: 16px;
  }

  /* Adjust margins and padding for about-container */
  .about-container {
    padding: 30px 0;
  }

  .Who-are {
    font-size: 2.5rem;
  }

  .who-dis {
    font-size: 1rem;
    margin: 0 20px; /* Reduced margin for mobile */
  }

  /* Features grid changes to single column on mobile */
  .features-grid {
    grid-template-columns: 1fr;
  }

  .feature-card {
    width: 100%;
    padding: 20px;
  }

  /* Adjust CTA button for mobile */
  .cta-buttonBA {
    width: 100%;
    padding: 15px;
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  /* Further tweaks for extra small screens */
  .hero-section {
    height: 250px;
  }

  .hero-aboutA {
    font-size: 20px;
    margin-top: 30px;
  }

  .about-disA {
    font-size: 14px;
  }

  .who-dis {
    font-size: 0.9rem;
    margin: 0 10px;
  }

  .cta-buttonBA {
    padding: 12px;
    font-size: 16px;
  }
}

/* Footer Styling */
footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
}
