* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.home-container {
  font-family: Arial, sans-serif;
  width: 100%;
}

.logo {
  font-size: 28px;
  color: #fff;
  font-weight: bold;
}

.nav-links {
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 30px;
}

.nav-links li {
  position: relative;
}

.nav-links a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  padding: 10px;
  transition: border-bottom 0.3s;
}

.nav-links a:hover {
  border-bottom: 2px solid #fff;
}

/* Dropdown menu styling */
.dropdown-content {
  display: block;
  position: absolute;
  top: 40px;
  left: 0;
  background-color: #fff;
  color: #000;
  list-style: none;
  padding: 15px 20px;
  border-radius: 5px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
}

.dropdown-content li {
  margin-bottom: 10px;
}

.dropdown-content li:last-child {
  margin-bottom: 0;
}

.dropdown-content a {
  color: #000;
  text-decoration: none;
}

.dropdown-content a:hover {
  color: #007bff;
}

/* Hero section */
.hero-section {
  background-image: url('https://creativeblenddesign.com/wp-content/uploads/2024/02/cbd-AI-2024-blog.jpg');
  background-size: cover;
  background-position: center;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-text {
  text-align: center;
  color: #fff;
}

.hero-text h1 {
  font-size: 40px;
  margin-bottom: 20px;
}

.hero-text p {
  font-size: 18px;
  margin-bottom: 30px;
}

.cta-button {
  padding: 12px 24px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cta-button:hover {
  background-color: #0056b3;
}

/* Flexbox for the image and form */
.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.flex-container img {
  width: 700px;
  height: 700px;
  object-fit: cover;
}

.flex-container .contact-form {
  margin-left: 20px;
  flex: 1;
}

@media (max-width: 768px) {
  /* Adjust flexbox direction for mobile */
  .flex-container {
    flex-direction: column;
    text-align: center;
  }

  .flex-container img {
    width: 90%;
    height: auto;
  }

  .flex-container .contact-form {
    margin-top: 20px;
    margin-left: 0;
  }

  .hero-text h1 {
    font-size: 30px;
  }

  .hero-text p {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  /* Further adjustments for smaller devices */
  .flex-container img {
    width: 100%;
    height: auto;
  }

  .hero-text h1 {
    font-size: 24px;
  }

  .hero-text p {
    font-size: 14px;
  }

  .cta-button {
    padding: 10px 20px;
    font-size: 14px;
  }
}

.Our-Work-text{
  font-family: 'overpassheavy', sans-serif;

}
.Our-Work-disc{
  font-family: 'overpasssemibold', sans-serif;
  color: #000;

}
