/* global.css */

/* Font Definitions */
@font-face {
  font-family: 'Overpass_Regular';
  src: url('../../assets/fonts/overpassFont/Overpass_Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'overpasssemibold';
  src: url('../../assets/fonts/overpassFont/overpasssemibold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'overpassextrabold';
  src: url('../../assets/fonts/overpassFont/overpassextrabold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'overpassextralight';
  src: url('../../assets/fonts/overpassFont/overpassextralight.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'overpassheavy';
  src: url('../../assets/fonts/overpassFont/overpassheavy.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* Apply font globally, or you can use class names to apply them selectively */
.font-regular {
  font-family: 'Overpass_Regular', sans-serif;
}

.font-semibold {
  font-family: 'overpasssemibold', sans-serif;
}

.font-extrabold {
  font-family: 'overpassextrabold', sans-serif;
}

.font-extralight {
  font-family: 'overpassextralight', sans-serif;
}
.font-heavybold {
  font-family: 'overpassheavy', sans-serif;
}
