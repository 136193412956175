/* Footer Styles */
.footer {
  background-color: #0c1e34;
  color: white;
  padding: 40px 20px;
  /* margin-top: 50px; */
}

.page-title {
  font-family: 'overpasssemibold', sans-serif;
  color: white;
}

.policy-text {
  font-family: 'overpasssemibold', sans-serif;
  color: white;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-logo {
  flex: 1;
  margin-right: 40px;
  margin-top: 20px;
}

.footer-logo h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.footer-logo p {
  margin: 0;
  line-height: 1.5;
}

.footer-links {
  display: flex;
  flex: 2;
  justify-content: space-between;
}

.footer-column {
  flex: 1;
  margin-right: 20px;
}

.footer-column h3 {
  font-size: 18px;
  margin-bottom: 15px;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 10px;
}

.footer-column ul li a {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-column ul li a:hover {
  color: #ffcc00;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-logo {
    margin-right: 0;
    text-align: center;
    margin-bottom: 20px;
  }

  .footer-logo h2 {
    font-size: 20px;
  }

  .footer-logo p {
    font-size: 14px;
  }

  .footer-links {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .footer-column {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .footer-column h3 {
    font-size: 16px;
  }

  .footer-column ul li a {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-logo {
    margin-right: 0;
    text-align: center;
    margin-bottom: 20px;
  }

  .footer-links {
    flex-direction: column;
    margin-top: 20px;
  }

  .footer-column {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .footer-column h3 {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .footer-column ul li {
    margin-bottom: 8px;
  }

  .footer-column ul li a {
    font-size: 14px;
  }

  /* Ensure links are easily tappable */
  .footer-column ul li a {
    padding: 8px 0;
  }
}

@media (max-width: 480px) {
  .footer-logo h2 {
    font-size: 18px;
  }

  .footer-logo p {
    font-size: 12px;
  }

  .footer-links {
    flex-direction: column;
  }

  .footer-column h3 {
    font-size: 14px;
  }

  .footer-column ul li a {
    font-size: 12px;
  }
}
