/* Reset and general styles */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.home-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.hero-section-dev {
  background-image: url('../../assets/images/fingerssBG.jpeg');
  background-size: cover;
  background-position: center;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.hero-text-dev {
  margin-left: 60px;
  margin-right: 60px;
  margin-top: 50px;
  text-align: center;
}

.cta-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 18px;
  font-family: 'Overpass_Regular', sans-serif;
  margin-left: 100px;
  margin-bottom: 80px;
}

footer {
  background-color: #333;
  color: white;
  padding: 20px;
  text-align: center;
}

header {
  background-color: #333;
  color: white;
  padding: 20px;
  text-align: center;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .hero-text-dev h1 {
    font-size: 24px;
  }

  .hero-text-dev p {
    font-size: 16px;
    margin: 10px 0;
  }

  .cta-button {
    padding: 8px 16px;
    font-size: 16px;
    margin-left: 0;
  }

  .hero-text-dev {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 30px;
  }

  .ai-text-details {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 480px) {
  .hero-section-dev {
    height: auto;
    padding: 20px;
  }

  .hero-text-dev h1 {
    font-size: 20px;
  }

  .hero-text-dev p {
    font-size: 14px;
  }

  .cta-button {
    padding: 6px 12px;
    font-size: 14px;
  }

  .hero-text-dev {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
  }

  .ai-text-details {
    margin-left: 0;
    margin-right: 0;
  }
}

.ai-text {
  font-family: 'overpassextrabold', sans-serif;
  text-align: center;
  margin-top: 80px;
}

.ai-text-details {
  font-family: 'Overpass_Regular', sans-serif;
  margin-left: 100px;
  margin-right: 50px;
}

.text-details {
  font-family: 'Overpass_Regular', sans-serif;
  margin-left: 40px;
}

.icon-div {
  margin-left: 40px;
  margin-bottom: 40px;
}

.Digit-Transf {
  margin-top: 40px;
}


