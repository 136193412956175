/* Main container */
.life-coditas-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 100%; /* Make image full width */

  padding: 40px;
  background-color: #f7f9fc;
  flex-direction: row;
  /* font-family: 'Arial', sans-serif; */
}

/* Content section (Title and Paragraphs) */
.life-coditas-content {
  /* max-width: 500px; */
  text-align: left;
  margin-bottom: 40px;
}

.life-coditas-content h1 {
  font-size: 3rem;
  color: #0b2438;
  margin-bottom: 20px;
}

.life-coditas-content p {
  font-size: 1.2rem;
  color: #333;
  line-height: 1.8;
  margin-bottom: 20px;
}

/* Image section */
.life-coditas-image {
  margin-bottom: 40px;
}

.life-coditas-image img {
  width: 100%; /* Make the image responsive */
  height: auto;
  border-radius: 10px;
}

/* Footer section */
.life-coditas-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 800px;
}

.life-coditas-footer img {
  width: 200px;
  height: auto;
}

.career-link {
  font-size: 1.2rem;
  color: #007BFF;
  text-decoration: none;
  font-weight: bold;
}

.career-link:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
  .life-coditas-container {
      padding: 20px; /* Reduced padding for mobile */
  }

  .life-coditas-content h1 {
      font-size: 2.5rem; /* Smaller heading */
  }

  .life-coditas-content p {
      font-size: 1.1rem; /* Smaller paragraph text */
  }

  .life-coditas-image img {
      width: 100%; /* Make image full width */
  }

  .life-coditas-footer {
      flex-direction: column; /* Stack footer items vertically */
      align-items: center;
      text-align: center;
  }

  .career-link {
      margin-top: 20px; /* Add margin to the career link */
  }
}

.Life-title{
  font-family: 'overpassheavy', sans-serif;
  text-align: center;
}
.Life-title-dis{
  font-family: 'Overpass_Regular', sans-serif;
}