/* Container for the entire services section */
.services-section {
  background-color: #0d192e;
  color: white;
  padding: 50px 20px;
  text-align: center;
  font-family: 'Arial', sans-serif;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

/* Title and Description */
.services-title {
  font-size: 48px;
  margin-bottom: 10px;
  font-family: 'overpassheavy',sans-serif;

}

.services-descriptionG {
  font-size: 18px;
  margin-bottom: 40px;
  font-family: 'Overpass_Regular',sans-serif;
  color: #fff;
}

/* Grid Layout */
.services-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.service-card {
  background-color: #1a2b4e;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  width: 30%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.service-card:hover {
  transform: translateY(-10px);
}

/* Icons for each card */
.service-icon {
  font-size: 40px;
  margin-bottom: 15px;
}

.service-title {
  font-size: 24px;
  margin-bottom: 10px;
  color: #fff;
  font-family: 'overpasssemibold',sans-serif;

}

.service-descriptionG {
  font-size: 16px;
  font-family: 'Overpass_Regular',sans-serif;
  color: #BABABA;

}

/* Responsive layout for smaller screens */
@media (max-width: 768px) {
  .services-grid {
    flex-direction: column;
    align-items: center;
  }

  .service-card {
    width: 90%;
    margin-bottom: 30px;
  }
}
