/* Technologies.css */
.tech-stack-container {
  text-align: center;
  /* padding: 20px; */
  background-color: #f9f9f9;
  margin-top: 20px;
}

.title-name{
  font-family: 'overpassheavy', sans-serif;
}
.title-name-dis{
  font-family: 'Overpass_Regular', sans-serif;
}
.program-title{
  font-family: 'overpasssemibold', sans-serif;

}

h1.title-name {
  font-size: 28px;
  margin-bottom: 10px;
  color: #333;
}

p.title-name-dis {
  font-size: 18px;
  margin-bottom: 30px;
  color: #666;
}

.tech-section {
  margin-top: 30px;
  margin-left: 20%;
  margin-right: 20%;
}

h3 {
  font-size: 22px;
  margin-bottom: 10px;
  color: #555;
}

.tech-marquee-container {
  width: calc(100% - 80px); /* 40px margin from both sides */
  margin-left: 40px;
  margin-right: 40px;
}

.tech-item {
  display: inline-block;
  padding: 0 20px;
}

.tech-item img {
  height: 60px;
  display: block;
}
