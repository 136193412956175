.privacy-container {
    margin: 40px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 5%;
  }
  
  .privacy-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
  font-family:'overpassheavy', sans-serif;

  }

  .policy-discription{
  font-family:'Overpass_Regular', sans-serif;
    
  }
  
  .title-text{
  font-family:'overpasssemibold', sans-serif;

  }
  .interpretation-title{
  font-family:'overpasssemibold', sans-serif;

  }

  h2 {
    font-size: 1.5rem;
    color: #007bff;
    margin-top: 30px;
  }
  
  h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #444;
  }
  
  p {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 15px;
  }
  
  ul {
    margin-left: 20px;
    list-style-type: disc;
  }
  
  ul li {
    margin-bottom: 10px;
    color: #666;
  }
  
  @media (max-width: 768px) {
    .privacy-container {
      padding: 15px;
    }
  
    .privacy-title {
      font-size: 1.5rem;
  font-family:'overpasssemibold', sans-serif;

    }
  
    h2 {
      font-size: 1.2rem;
    }
  
    p, ul li {
      font-size: 0.9rem;
    }
  }
  